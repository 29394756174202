<template>
  <div class="decorationlist">
    <div class="title">
      <div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button type="primary" @click="addTemplate">添加模版</el-button>
      </div>
    </div>

    <div class="auto-table-flex">
      <div class="body_box">
        <div class="body_box_item" v-for="(item ,index) in tableData" :key="index">
          <img
            :src="item.main_image"

            class="img"
            alt=""
          />
          <div class="title">{{item.name}}</div>
          <div class="bottom">
            <div class="left">{{item.create_time}}</div>
            <div class="right">
              <el-button type="text" @click="editclcik(item)">编辑</el-button
              ><el-button type="text" @click="deleteclcik(item)">删除</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
      <script>
export default {
  name: "decorationlist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        is_activity:0,//活动模板列表接口加一个 is_activity 参数，添加或编辑活动时调用值为1，其他为0
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
  },
  methods: {
    editclcik(item) {
      this.$router.push({
        path: "/decoration",
        query: {
          id: item.id,
        },
      });
    },
    deleteclcik(item) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.decoration
        .template_delete({id:item.id})
        .then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.hqlist();
        })
       
          
        })
    },
    addTemplate() {
      this.$router.push({
        path: "/decoration",
      });
    },
    hqlist() {
      this.loading = true;
      this.$api.decoration
        .template_list(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
      <style lang="scss">
.decorationlist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  background: #eeeeee;
  padding: 20px;

  .title {
    // padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      .inputs {
        // width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .body_box {
    // background-color: red;
    height: 100%;
    padding-top: 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: auto;
    .body_box_item {
      // flex: 1;
      height: 331px;
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #f4f4f4;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .body_box_item:not(:nth-child(4n)) {
      margin-right: calc(4% / 3);
    }
    .img {
      width: 300px;
      height: 220px;
    }
    .title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      padding: 20px;
      box-sizing: border-box;
    }
    .bottom {
      padding: 0 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
      .right {
      }
    }
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
      
    